<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-form class="row" @submit="saveMission" @reset="onReset" v-if="show">
              <div class="col-md-6">
                <h2 class="title-block">THÔNG TIN CHÍNH</h2>
                <b-form-group
                    id="input-group-0"
                    label="Icon nhiệm vụ (*):"
                    label-for="input-0"
                >
                  <div style="display: flex">
                    <div style="height: 100px; margin-right: 20px" v-if="form.icon.length >0">
                      <img
                          style="width: 38px; height: 38px"
                          :src="form.icon"
                          alt=""
                      />
                    </div>
                    <b-form-file
                        @change="onImageChange($event,'icon')"
                        placeholder="chọn icon"
                        type="file"
                        accept="image/*"
                        :required="form.icon.length > 0?false:true"

                    ></b-form-file>
                  </div>
                </b-form-group>
                <b-form-group
                    id="input-group-1"
                    label="Tên nhiệm vụ(*):"
                    label-for="input-1"
                >
                  <b-form-input
                      id="input-1"
                      v-model="form.title"
                      required
                      placeholder="Tên nhiệm vụ"
                      maxlength="100"
                  ></b-form-input>
                </b-form-group><!--
                <b-form-group
                    id="input-group-2"
                    label="Mô tả nhiệm vụ (*)"
                    label-for="input-2"
                >
                  <b-form-textarea
                      id="input-1"
                      v-model="form.desc"
                      required
                      placeholder="Nhập mô tả nhiệm vụ"
                      max="255"
                  ></b-form-textarea>
                </b-form-group>-->

                <b-form-group
                    id="input-group-3"
                    label="Gắn code nhiệm vụ (*):"
                    label-for="input-3"
                >
                  <b-form-select
                      id="input-3"
                      v-model="form.code"
                      :options="mission_type_option"
                      required
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                    id="input-group-3"
                    label="Router:"
                    label-for="input-router"
                >
                  <b-form-input
                      id="input-router"
                      v-model="form.router"
                      placeholder="Router"
                      maxlength="100"
                  ></b-form-input>
                </b-form-group>


              </div>

              <div class="col-md-6">
                <h2 class="title-block">CÀI ĐẶT THÔNG BÁO</h2>
                <b-form-group
                    id="input-group-4"
                    label="Tiêu đề thông báo:"
                    label-for="input-4"
                >
                  <b-form-input
                      id="input-4"
                      v-model="form.noti_title"
                      placeholder="Nhập tiêu đề thông báo"
                      maxlength="50"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="input-group-5"
                    label="Nội dung thông báo"
                    label-for="input-5"
                >
                  <b-form-textarea
                      id="input-5"
                      v-model="form.noti_desc"
                      placeholder="Nhập nội dung thông báo "
                      maxlength="300"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group label="Chọn hình ảnh thông báo (optional):">
                  <div style="display: flex">
                    <div style="height: 100px; margin-right: 20px" v-if="form.noti_thumb.length >0">
                      <img
                          style="width: 38px; height: 38px"
                          :src="form.noti_thumb"
                          alt=""
                      />
                    </div>
                    <b-form-file
                        @change="onImageChange($event,'noti')"
                        placeholder="chọn ảnh thumb"
                        accept="image/*"
                        type="file"
                    ></b-form-file>
                  </div>
                </b-form-group>
              </div>





              <div class="col-md-12">
                <b-button type="submit" variant="primary">{{
                    is_edit ? "Cập nhập" : "Xác nhận"
                  }}</b-button>
                <router-link v-if="is_edit" :to="'/campaign/mission'">
                  <b-button variant="danger">Hủy bỏ</b-button>
                </router-link>
                <b-button v-else type="reset" variant="danger">Làm mới</b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
Vue.component("multiselect", Multiselect);
const CampaignRepository = RepositoryFactory.get("Campaign");
const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  name: "FormMission",
  mixins: [Common],
  components: {
  },
  data() {
    return {
      form: {
        title: "",
        code: "",
        desc: "  ",
        noti_title: "",
        noti_desc: "",
        noti_thumb: "",
        num: "",
        is_multiple: "",
        conditions: "",
        status: 1,
        icon: '',
        router: '',

      },
      bank_conditions: {
        type_direct:0,
        list_bank_code:[]
      },
      payment_conditions: {
        total_amount:'',
        min_per_trans:'',
        list_service_code:[]
      },
      mission_type_option: [{ text: "Chọn loại nhiệm vụ", value: null }],
      bank_option: [],
      payment_option: [],
      show: true,
      is_edit: false,
    };

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lí nhiệm vụ ", route: "/campaign/mission" },
      { title: "Thêm nhiệm vụ" },
    ]);
  },
  methods: {
    onImageChange(e,type) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.imageIconPreview = URL.createObjectURL(files[0]);
      this.images = files[0];
      this.uploadMedia(type);
      //this.createImage(files[0]);
    },
    uploadMedia: async function (type) {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              if (type =='noti'){
                this.form.noti_thumb = response.data.data.link;
              }else {
                this.form.icon = response.data.data.link;
              }
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    async loadMission(id) {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getMission(id);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          this.form.title = body.data.title;
          this.form.desc = body.data.desc;
          this.form.code = body.data.code;
          this.form.noti_title = body.data.noti_title;
          this.form.noti_desc = body.data.noti_desc;
          this.form.num = body.data.num;
          this.form.router = body.data.router;
          if (body.data.icon!= null){
            this.form.icon = body.data.icon;
          }
          if (body.data.noti_thumb!= null){
            this.form.noti_thumb = body.data.noti_thumb;
          }
          if(this.form.code ==="LINK_BANK"){
              let conditions = JSON.parse(body.data.conditions);
              this.bank_conditions.type_direct = conditions.type_direct;
              this.bank_conditions.list_bank_code = conditions.list_bank_code;
          }
          if(this.form.code ==="PAYMENT"){
              let conditions = JSON.parse(body.data.conditions);
              this.payment_conditions.min_per_trans = conditions.min_per_trans;
              this.payment_conditions.total_amount = conditions.total_amount;
              this.payment_conditions.list_service_code = conditions.list_service_code;
          }
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async getCodes() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getOptions();
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          this.options = body.data.mission_code

          let that = this;
          this.options.forEach((item) => {
            that.mission_type_option.push(item);
          });
          body.data.bank_direct_link.forEach((item) => {
            that.bank_option.push(item);
          });
          body.data.payment_service.forEach((item) => {

            that.payment_option.push(item);
          });
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    saveMission: async function () {

      this.$bus.$emit("show-loading", true);
      if (this.form.code === "LINK_BANK"){
          this.form.conditions = JSON.stringify(this.bank_conditions)
          this.form.num =1;
      }
      if (this.form.code === "PAYMENT"){
          this.form.conditions = JSON.stringify(this.payment_conditions)
          this.form.is_multiple=1;
      }
      let params = this.form;
      if (this.is_edit) {
        this.updateMission(params);
        return false;
      }

      CampaignRepository.createMission(params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.$router.push({ name: "mission_campaign" });
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    updateMission: async function (params) {
      this.$bus.$emit("show-loading", true);

      CampaignRepository.updateMission(this.$route.params.id, params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.$router.push({ name: "mission_campaign" });
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        title: "",
        code: "",
        desc: "",
        noti_title: "",
        noti_desc: "",
        noti_thumb: "",
        num: "",
        is_multiple: "",
        conditions: "",
        status: 1,
        icon: '',
      }
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
  created() {
    this.getCodes();
    if (this.$route.params.id) {
      this.is_edit = true;
      this.loadMission(this.$route.params.id);
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.title-block{
  color: #FF0000;
  font-size: 18px;
  font-weight: bold;
}
</style>
